import React from "react"
import { Result, Button } from "antd"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const styles = {
  resultWrapper: {
    width: "40vw",
    margin: "auto",
  },
  imageWrapper: {
    width: "100%",
  },
}

const Empty = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "blank-canvas.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Result
      style={styles.resultWrapper}
      icon={
        <Img
          style={styles.imageWrapper}
          fluid={data.image.childImageSharp.fluid}
        />
      }
      subTitle="Sorry, nothing here.."
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  )
}

export default Empty
